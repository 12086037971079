import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Molecular Solutions Care Health  works in close association with RH. It was started with the sole purpose of making advanced molecular tests available to anyone in need.`}</p>
    <p>{`We are a group of molecular biologists, biochemists and microbiologists who love developing novel ways of testing, thereby making them easier, more reliable and inexpensive.`}</p>
    <p>{`Mission:  To make healthcare, with a special focus on diagnostics, available and accessible.`}</p>
    <p>{`Vision:  A world where anyone who is sick does not worry about how they are going to manage their treatment and how the treatment will affect their lives and the lives of their loved ones.`}</p>
    <p>{`hiv testing`}</p>
    <ol>
      <li parentName="ol">{`Viral load testing`}</li>
      <li parentName="ol">{`Drug resistance genotyping`}</li>
      <li parentName="ol">{`Pre-ART and on-ART testing packages`}</li>
      <li parentName="ol">{`Pre-marital and pre-conception packages`}</li>
    </ol>
    <p>{`viral hepatitis`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Viral loads for HCV, HBV​`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Subtype genotyping for HCV`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Dialysis packages`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Obstetric packages`}</p>
      </li>
    </ol>
    <p>{`screening tests`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Hep B card test`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Hep C card test`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`VDRL for syphilis`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`4th generation HIV testing`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`3rd generation HIV testing`}</p>
      </li>
    </ol>
    <p>{`customized assays`}</p>
    <p>{`Do you want a test that can be made cheaper, but of the same or higher quality, than what is currently available?`}</p>
    <p>{`Contact us!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      